import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const images = [
    '2398731.jpg', 
    '518722.jpg', 
    '6533039.jpg', 
    '6186100.jpg', 
    '626730.jpg', 
    '7045538.jpg', 
    '7166802.jpg'
];

const TFPPage = () => {

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <h1>Monday 10/10/2022</h1>
      <h2>
        Possible Locations (Georgetown):
      </h2>
      <div>
        {images.map(img => (
            <img key={img} src={`https://mhnews3bucket-s3uploadbucket-1pvt75lwbyujd.s3.amazonaws.com/${img}`} />
        ))}
      </div>

      <h2>Lighting Inspiration</h2>
      <p>
      <video style={{ display: 'block', width: '75%' }} controls>
        <source src="https://aight-images.s3.amazonaws.com/vid/lights.mp4" type="video/mp4" />
      </video>
      </p>

      <Link to="/">Go back to the homepage</Link>
    </Layout>
  );
};

export default TFPPage;
